import axios from 'axios';
import {
	AdminReq, AdminUserReq, GetPermissionsResponse, GetRolesResponse,
	GetAdminsListResponse, Admin, EditAdmin, UploadUrlForKycDocumentResponse, Document,
	BankChangeRequest, GetPackagesRequest, Package, FetchMasterCourse, FetchModuleElement, AssignedAssignment, courseBundleResponse, FetchCurriculum, FetchContentsRequest, FetchChapterContentResponse, DeleteChapterContentRequest
} from '../modules/common/contracts/dashboard';
import { FetchBatchOffer, FetchCourseStatus, FetchedUsers, FetchPublicBatch, FetchPublicSale, FetchPublicSaleDetail, PCourse, Student } from '../modules/dashboard/interfaces';
import { roles } from '../modules/edumaticaAcademy/enums';
import {
	CREATE_ADMIN_USER, CREATE_ROLE, GET_PERMISSIONS, GET_ROLES, GET_ADMINS, EDIT_ADMIN_USER,
	RESET_PASSWORD_BY_ADMIN, ASSIGN_ROLE, GET_USERS, UPDATE_USER_STATUS,
	GET_KYC_DOCUMENT_UPLOAD_URL, GET_USER, DELETE_ADMIN, EDIT_ROLE, GET_ADMIN,
	POST_EMAIL_PASWORD, GET_KYC_STATUS_LIST, GET_KYC_DATA, UPDATE_KYC_DATA,
	DELETE_KYC_DOCUMENT, UPDATE_BANK_ACCOUNT, GET_KYC_DOCUMENT_DOWNLOAD_URL, GET_PACKAGES, GET_CUSTOMER_PACKAGE,
	CUSTOMER_PACKAGE_APPROVAL, GET_KYC_VIDEO_DOWNLOAD_URL,
	GET_USERS_DETAILS, ORG_THEMES_DATA, SET_THEME, GET_THEME, GET_CURRENT_LOGO_AND_SUBDOMAIN, SET_CURRENT_LOGO_AND_SUBDOMAIN, UPDATE_USER_PREFERENCES, RUN_CUSTOM_SCRIPT, RUN_UPDATED_SCHEDULES, FETCH_USERS, VERIFY_USER, REG_USER, PUBLIC_COURSE, PUBLIC_COURSE_BU, PUBLIC_COURSE_BATCH, PUBLIC_COURSE_STUDENT, PUBLIC_COURSE_STUDENTSTATUS, PUBLIC_COURSE_STUDENTCERTIFICATE, PUBLIC_COURSE_SALE, PUBLIC_COURSE_SALEDETAIL, PUBLIC_COURSE_BATCHFEE, UPDATE_BLOCKED_PERMISSION, GET_BLOCKED_PERMISSION, GET_ROLES_SECAND, GET_USERS_DETAILS_ROLE, UPDATE_USER_DETAILS, DELETE_USER, GET_ORGANIZATIONS_LIST, CREATE_COLLEGE_GROUP, GET_ALL_COLLEGE_GROUPS, DELETE_COLLEGE_GROUP, UPDATE_COLLEGE_GROUP, GET_GROUP_BY_OWNERID, HARD_DELETE_USER, UPDATE_USER_LOGIN, UPDATE_ENROLL, MASTERCOURSE_ROUTE, LEBOOK_ROUTE, ADD_DCONTENT_ROUTE, GET_GROUP_DISCUSSION_FORM, ASSIGNED_ASSIGNMENT_NEW, FETCH_ALL_ASSETS, ANNOUNCEMENT, GET_COURSE_NAME, GET_EXAM_DATA, CURRICULUM_ROUTE, MASTERCOURSE_DELETE_ROUTE, LEBOOK_ROUTE_DELETE, DELETE_ONSCREEN_EVALUTION_DOC, DELETE_GD_DOC, DELETE_COLLEGE_BASED_DOC, DELETE_ASSIGNMENT, GET_CONTENTS, DELETE_ORG_CHAPTER_CONTENT
} from './routes';



export const getThemesData = async () => {
	const response = await axios.get(ORG_THEMES_DATA)
	return response.data.themesData
}

export const setCurrentTheme = async (themeName: string, ownerId: string) => {
	const response = await axios.post(SET_THEME, { themeName, ownerId })
	return response.data
}

interface Theme {
	_id?: string,
	themeName: string,
	colors: {
		primary: string,
		secondary: string,
		surface: string,
		backdrop: string,
	}
}

interface ThemeResponse {
	theme: Theme
}

interface LogoAndSubdomain {
	theme: {
		logo: string;
		subdomain: string;
		tagline: string;
		redirectUrl: string;
		mainTitle: string;
		courseBool: boolean;
		titleImage: string;
		demoDeepLinkBool: boolean;
		smsBool: boolean,
		emailBool: boolean,
		certRedirector: string,
		queryParams: string[]
	}
}

export const getCurrentTheme = async (ownerId: string) => {
	const response = await axios.get<ThemeResponse>(GET_THEME, { params: { ownerId } })
	return response.data.theme
}

export const getCurrentLogoAndSubdomain = async (ownerId: string) => {
	const response = await axios.get<LogoAndSubdomain>(GET_CURRENT_LOGO_AND_SUBDOMAIN, { params: { ownerId } })
	return response.data.theme
}

export const setCurrentLogoAndSubdomain = async (ownerId: string, logo: string, titleImage: string, subdomain: string, tagline: string, redirectUrl: string, mainTitle: string, courseBool: boolean, demoDeepLinkBool: boolean, smsBool: boolean, emailBool: boolean, certRedirector: string, queryParams: string[]) => {
	const response = await axios.post(SET_CURRENT_LOGO_AND_SUBDOMAIN, { logo, titleImage, subdomain, tagline, ownerId, redirectUrl, mainTitle, courseBool, demoDeepLinkBool, smsBool, emailBool, certRedirector, queryParams })
	return response.data
}


export const createAdminUser = async (adminReq: AdminReq, adminUserReq: AdminUserReq) => {
	const response = await axios.post(CREATE_ADMIN_USER, { adminReq, adminUserReq });
	return response;
};

export const createRole = async (name: string, permissions: string[]) => {
	const response = await axios.post(CREATE_ROLE, { name, permissions });
	return response;
};

export const getRoles = async () => {
	const response = await axios.get<GetRolesResponse>(GET_ROLES);
	return response.data.roles;
}

export const getPermissions = async () => {
	const response = await axios.get<GetPermissionsResponse>(GET_PERMISSIONS);
	return response.data.permissions;
}

export const getAdmins = async (data: any) => {
	const url = `${GET_ADMINS}?${data}`;
	const response = await axios.get<GetAdminsListResponse>(url);
	return response.data.admins;
}

export const editAdminUser = async (data: any) => {	//crosscheck
	const response = await axios.post(EDIT_ADMIN_USER, data);
	return response;
};

export const resetPasswordByAdmin = async (usernameToReset: string, newPassword: string) => {
	const response = await axios.post(RESET_PASSWORD_BY_ADMIN, { usernameToReset, newPassword });
	return response;
}

export const assignRole = async (name: string, permissions: string[]) => {//crosscheck
	const response = await axios.post(ASSIGN_ROLE, { name, permissions });
	return response;
}

export const deleteAdmin = async (emailId: string) => {
	const response = await axios.post(DELETE_ADMIN, null, { params: { emailId } });
	return response;
}

export const editRole = async (name: string, permissions: string[]) => {
	const response = await axios.post(EDIT_ROLE, { name, permissions });
	return response;
};

export const updateEnroll = async (ownerId: string | undefined) => {
	const response = await axios.post(UPDATE_ENROLL, { ownerId });
	console.log("response for update enroll---")
	return response;
};

export const getAdminData = async () => {
	const response = await axios.get(GET_ADMIN);
	return response.data.admin;
}

export const sendEmailPassword = async (name: string, email: string, newPassword: string) => {
	const response = await axios.post(POST_EMAIL_PASWORD, { name, email, newPassword });
	return response;
}

export const getUsers = async (data: any) => {//crosscheck
	const url = `${GET_USERS}?${data}`;
	const response = await axios.get(url);
	return response.data.users;
}
export const getUserDetailsForRole = async (data: any) => {//crosscheck
	const url = `${GET_USERS_DETAILS_ROLE}?${data}`;
	const response = await axios.get(url);
	return response.data.users;
}
export const getRolesSecand = async () => {//crosscheck
	const url = `${GET_ROLES_SECAND}`;
	const response = await axios.get(url);
	return response.data.users;
}

export const fetchMasterCourse = async (ownerId?: string) => {
	console.log("param for ownerId---",ownerId)

	const param = ownerId ? { ownerId: ownerId } : {}
	console.log("param for master---",param)

	const response = await axios.get<{ masterCourses: FetchMasterCourse[] }>(MASTERCOURSE_ROUTE, { params: param })
	console.log("response.data---for mastercourse :",response.data)
	return response.data.masterCourses
}
export const deleteMasterCourse = async (id?: string , ownerId?: string) => {
	const response = await axios.delete(MASTERCOURSE_DELETE_ROUTE, { params: { id: id , ownerId:ownerId } })
	return response.data
}
  
export const deleteEBook = async (id: string,  ownerId?: string) => {
	const response = await axios.delete(LEBOOK_ROUTE_DELETE, { params: { id: id ,  ownerId: ownerId} })
	return response.data
}
export const deleteOrgChapterContent = async (ownerId?: string) => {
	const response = await axios.delete(DELETE_ORG_CHAPTER_CONTENT, { params: { ownerId: ownerId } });
	console.log("response.data-->", response)
	return response.data;
};
  
export const deleteOnScreenFile = async (document: any, _id?: string , ownerId?: string) => {
	const response = await axios.delete(DELETE_ONSCREEN_EVALUTION_DOC, { params: { document: document, id: _id, ownerId: ownerId } })
	console.log("response.data for onscreen evaluation--",response.data)
	return response.data
  }

  export const deleteGdDoc = async (document: any, _id?: string , ownerId?: string) => {
	const response = await axios.delete(DELETE_GD_DOC, { params: { document, _id , ownerId} });
	return response.data;
  };

export const deleteCollegeBasedDoc = async (uuid?: string, docType?: string, ownerId?: string) => {
	const response = await axios.delete(DELETE_COLLEGE_BASED_DOC, { params: { uuid, docType, ownerId } });
	return response.data;
  };

  export const deleteAssignmentById = async (assignmentId: string , ownerId?: string ) => {
	const response = await axios.delete(DELETE_ASSIGNMENT, { params: { assignmentId , ownerId } })
	return response.data
  }
  

  export const fetchAssets = async (ownerId?: string) => {
	const param = ownerId ? { ownerId: ownerId } : {}
	console.log("param for asset---",param)
	const response = await axios.get(FETCH_ALL_ASSETS, { params: param })
	console.log("response.data---for asset :",response)
	return response.data.assets
  }

  export const fetchAnnouncements = async (ownerId?: string) => {
	const param = ownerId ? { ownerId: ownerId } : {}
	console.log("param for announce---",param)
	const response = await axios.get(ANNOUNCEMENT, { params: param })
	console.log("response.data---for announce :",response)

	return response.data.announcements
  }

  export const getCourseBundles = async () => {
    const response = await axios.get<courseBundleResponse>(GET_COURSE_NAME)
    return response.data.pcs
}

export const getExamData = async (ownerId?: string) => {
	const param = ownerId ? { ownerId: ownerId } : {}
	console.log("param for exam---",param)
	const response = await axios.get(GET_EXAM_DATA, { params: param });
	console.log("response.data---for exam :",response)
	return response.data;
  };

  export const fetchCurriculum = async (ownerId?: string) => {
	const param = ownerId ? { ownerId: ownerId } : {}
	console.log("param for curri---",param)
	const response = await axios.get<{ curriculums: FetchCurriculum[] }>(CURRICULUM_ROUTE, { params: param })
	console.log("response.data---for curri :",response)
	return response.data.curriculums
  }

export const fetchSignedContents = async (ownerId?: string) => {
	const param = ownerId ? { ownerId: ownerId } : {}
	console.log("param for coursecontent---", param)
	const response = await axios.get<FetchChapterContentResponse>(GET_CONTENTS, { params: param })
	console.log("response.data-->", response)
	return response.data.contentList
}

  export const fetchAssignedAssignmentNew = async (ownerId?: string) => {
	const param = ownerId ? { ownerId: ownerId } : {}
	const response = await axios.get<{ assignments: AssignedAssignment[] }>(ASSIGNED_ASSIGNMENT_NEW, { params: param })
	return response.data.assignments
  }

  export const fetchEBook = async (ownerId?: string) => {
	const param = ownerId ? { ownerId: ownerId } : {}
	const response = await axios.get<{ books: any[] }>(LEBOOK_ROUTE, { params: param })
	console.log("response.data--",response.data)
	return response.data.books
  }

  export const getGdFormBy_Id = async (ownerId?: string) => {
	const param = ownerId ? { ownerId: ownerId } : {}
	console.log("ownerId in response ---",param)
	const response = await axios.get(GET_GROUP_DISCUSSION_FORM, { params: param })
	console.log("response.data--",response.data)
	return response.data
  }
  
  export const getChapterContent = async (courseId: string, curriculumId: string, chapterId: string) => {
	const response = await axios.get<{ contents: FetchModuleElement[] }>(ADD_DCONTENT_ROUTE, { params: { courseId: courseId, curriculumId: curriculumId, moduleId: chapterId } })
	console.log("content respp----",response)
	return response.data.contents
  }

export const getOrgList = async () => {
	const url = `${GET_ORGANIZATIONS_LIST}`;
	const response = await axios.get(url);
	return response.data.orgList;
}

export const getAllCollegeGroups = async () => {
	const url = `${GET_ALL_COLLEGE_GROUPS}`;
	const response = await axios.get(url);
	return response.data.responseData;
}

export const createCollegeGroup = async (payload:any) => {
	const response = await axios.post(CREATE_COLLEGE_GROUP, payload);
	return response;
};

export const updateCollegeGroup = async (payload:any) => {
	const response = await axios.put(UPDATE_COLLEGE_GROUP, payload);
	return response;
};

export const getGroupByOwnerId = async (ownerId: string) => {
	const response = await axios.get(GET_GROUP_BY_OWNERID, { params: {ownerId} })
	return response.data.responseData
  }
// New Added User Search
export const getUserDetails = async (userType: string, toDate?: Date, fromDate?: Date, toNotiDate?: Date, fromNotiDate?: Date) => {//crosscheck
	const response = await axios.get(GET_USERS_DETAILS, { params: { userType, toDate, fromDate, toNotiDate, fromNotiDate } });
	return response.data.users;
}

export const fetchUsers = async (usertype: 'tutor' | 'student' | 'parent' | 'orgadmin') => {
	const response = await axios.get<{ users: FetchedUsers[] }>(FETCH_USERS, { params: { usertype: usertype } });
	return response.data.users;
}

export const fetchPubliccourse = async () => {
	const response = await axios.get<{ data: PCourse[] }>(PUBLIC_COURSE);
	return response.data.data;
}

export const blockUnblockPC = async (id: string, status: number) => {
	const response = await axios.put(PUBLIC_COURSE_BU, { id: id, status: status });
	return response;
}

export const getPBatch = async (id: string) => {
	const response = await axios.get<{ data: FetchPublicBatch[] }>(PUBLIC_COURSE_BATCH, { params: { courseId: id } });
	return response.data.data;
}

export const getBatchStudents = async (courseId: string, batchId: string,) => {
	const response = await axios.get<{ data: Student[] }>(PUBLIC_COURSE_STUDENT, { params: { courseId: courseId, batchId: batchId } })
	return response.data.data
}

export const getBatchFee = async (courseId: string, batchId: string,) => {
	const response = await axios.get<{ data: FetchBatchOffer[] }>(PUBLIC_COURSE_BATCHFEE, { params: { courseId: courseId, batchId: batchId } })
	return response.data.data
}

export const getCouresFee = async (courseId: string) => {
	const response = await axios.get<{ data: FetchBatchOffer[] }>(PUBLIC_COURSE_BATCHFEE, { params: { courseId: courseId } })
	return response.data.data
}

export const postBatchFee = async (data: FetchBatchOffer[]) => {
	const response = await axios.post(PUBLIC_COURSE_BATCHFEE, data)
	return response.data
}

export const getCourseStatusTutor = async (id: string, batchId: string) => {
	const response = await axios.get<{ data: FetchCourseStatus[] }>(PUBLIC_COURSE_STUDENTSTATUS, { params: { id: id, batchId: batchId } })
	return response.data.data
}

export const getCourseStatusCertificate = async (id: string, batchId: string, studentId: string) => {
	const response = await axios.get<{ data: FetchCourseStatus[] }>(PUBLIC_COURSE_STUDENTCERTIFICATE, { params: { id: id, batchId: batchId, studentId: studentId } })
	return response.data.data
}

export const getSale = async (courseId: string, batchId: string, studentId: string) => {
	const response = await axios.get<{ data: FetchPublicSale[] }>(PUBLIC_COURSE_SALE, { params: { courseId: courseId, batchId: batchId, studentId: studentId } })
	return response.data.data
}

export const getSaleDetails = async (courseId: string, batchId: string, studentId: string) => {
	const response = await axios.get<{ data: FetchPublicSaleDetail[] }>(PUBLIC_COURSE_SALEDETAIL, { params: { courseId: courseId, batchId: batchId, studentId: studentId } })
	return response.data.data
}

export const validateuser = async (ownerId: string, mobileNo: string, emailId: string, userType: string, mobileSignUp: boolean) => {
	const regtype = mobileSignUp ? 'mobileNo' : 'emailId'
	const response = await axios.post(VERIFY_USER, { ownerId: ownerId, mobileNo: mobileNo, emailId: emailId, userType: userType, regtype: regtype });
	return response;
}

export const registerUser = (orgCode: string, password: string, userType: string, mobileNo: string, emailId: string, name: string, tutorgname: string, mobileSignup: boolean) => {
	const regtype = mobileSignup ? 'mobileNo' : 'emailId'
	return axios.put(REG_USER, { orgCode: orgCode, mobileNo: mobileNo, password: password, userType: userType, emailId: emailId, name: name, tutorgname: tutorgname, regtype: regtype });
};

export const updateUserStatus = async (userType: string, userId: string, roleStatus: string, roleStatusReason: string) => {
	const response = await axios.post(UPDATE_USER_STATUS, { userType, userId, roleStatus, roleStatusReason });
	return response;
}
//UPDATE_USER_LOGIN

export const updateUseLogIn = async (data: object) => {
	const response = await axios.post(UPDATE_USER_LOGIN, data);
	console.log("response of back---", response)
	return response;
}
export const changeBankAccount = async (data: BankChangeRequest) => {
	const response = await axios.post(UPDATE_BANK_ACCOUNT, data);
	return response;
}

export const fetchUploadUrlForKycDocument = async (document: Document) => {
	const response = await axios.get<UploadUrlForKycDocumentResponse>(GET_KYC_DOCUMENT_UPLOAD_URL, { params: document });
	return response.data;
};

export const uploadFileOnUrl = async (url: string, file: File) => {
	// formData.append("Content-Type", file.type);
	// Object.entries(url.fields).forEach(([k, v]) => {
	//   formData.append(k, v);
	// });
	const response = await axios.put(url, file, { headers: { 'Content-Type': file.type } });
	return response.data;
};

export const getUser = async (userType: string, userId: string) => {
	const response = await axios.get(GET_USER, { params: { userType, userId } });
	return response.data.user;
}

export const updateUserPreferences = async (updateReq: {
	userType: string,
	userId: string,
	purchasedId: string,
	preferences: {
		userPreferences: {
			meetingPreference: string,
			autoRecord: boolean
			wapAccountSid: string,
			instituteType: string,
			wapAuthToken: string,
			wapFrom: string,
			universityPrefixCode:String,
			wapBox: string,
			wapChannelId: string,
			wapApiKey: string,
			wapApiSecret: string,
			attendanceType: string,
			onJobTrainingEnable:boolean ,
			multipleParticularEnable:boolean ,
			paymentReciptEnable:boolean ,
			libraryOptionalFlag:boolean ,
			isAccessionNumberOptional:boolean,
			deleteAssignedFeeStructure:boolean,
			deletePaymentReceipt:boolean,
			edumaticaStyleReciept:boolean,
			CRMMailIds:string[],
			customContactNumbers:string[],
			isPdfDownloadable:boolean,
			isFeeCollection:boolean,
			isLibraryBookDelete:boolean
		},
		packagePreferences: {
			isContentDownloadable: number,
			contentDownloadRole: roles[],
			recordingDownloadRole: roles[],
			isRecordingDownloadable: number
		}
	}
}) => {
	const response = await axios.patch(UPDATE_USER_PREFERENCES, updateReq);
	return response.data;
}

export const getKycStatusList = async (data: any) => {//crosscheck
	const url = `${GET_KYC_STATUS_LIST}?${data}`;
	const response = await axios.get(url);
	return response.data.kycStatus;
}

export const getKycData = async (userType: string, userId: string) => {
	const response = await axios.get(GET_KYC_DATA, { params: { userType, userId } });
	return response.data.kycData;
}

export const updateKycStatus = async (obj: any) => {
	const response = await axios.post(UPDATE_KYC_DATA, obj);
	return response;
}
export const updateUserDetails = async (obj: any) => {
	const response = await axios.post(UPDATE_USER_DETAILS, obj);
	return response;
}
export const deleteUser = async (objectId: any) => {
	const response = await axios.delete(DELETE_USER, { params: {_id:objectId}} );
	return response;
}

export const hardDeleteUser = async (objectId: any) => {
	const response = await axios.delete(HARD_DELETE_USER, { params: {_id:objectId}} );
	return response;
}
export const deleteollegeGroup = async (groupId: any) => {
	const response = await axios.delete(DELETE_COLLEGE_GROUP, { params: {_id:groupId}} );
	return response;
}


export const delteUploadKycDocument = async (uuid: string) => {
	const response = await axios.delete(DELETE_KYC_DOCUMENT, { params: { uuid: uuid } });
	return response.data;
};

export const fetchDownloadUrlForKycDocument = async (uuid: string, ownerId: string) => {
	const response = await axios.get(GET_KYC_DOCUMENT_DOWNLOAD_URL, { params: { uuid, ownerId } });
	return response.data;
};

export const fetchDownloadUrlForKycVideo = async (uuid: string, ownerId: string) => {
	const response = await axios.get(GET_KYC_VIDEO_DOWNLOAD_URL, { params: { uuid, ownerId } });
	return response.data;
};

export const getEdumaticaPackages = async (data: GetPackagesRequest) => {
	const response = await axios.get<any>(GET_PACKAGES, { params: data }); //<Package[]>
	return response.data;
}

export const createPackage = async (data: any) => {
	const response = await axios.post(GET_PACKAGES, data);
	return response.data;
}

export const updateMasterPackage = async (data: any) => {
	const response = await axios.patch(GET_PACKAGES, data);
	return response.data;
}

export const getUserPackage = async (ownerId: string) => {
	const response = await axios.get(GET_CUSTOMER_PACKAGE, { params: { ownerId } });
	return response.data.package;
}

export const postPackage = async (pkg: any) => {
	const response = await axios.post(CUSTOMER_PACKAGE_APPROVAL, pkg);
	return response.data;
}

export const updatePackage = async (pkg: any) => {
	const response = await axios.patch(CUSTOMER_PACKAGE_APPROVAL, pkg);
	return response.data;
}

export const getPackageApprovalByIds = async (ownerId: string, entityId: string) => {
	const response = await axios.get(CUSTOMER_PACKAGE_APPROVAL, { params: { ownerId, entityId } });
	return response.data.packages;
}

export const getPackageApprovalById = async (packageId: string) => {
	const response = await axios.get(CUSTOMER_PACKAGE_APPROVAL + '/' + packageId);
	return response.data;
}

export const runCustomScript = async () => {
	const response = await axios.get(RUN_CUSTOM_SCRIPT);
	return response.data;
}

export const runUpdatedSchedules = async () => {
	const response = await axios.get(RUN_UPDATED_SCHEDULES);
	return response.data;
}

export const updatePermission = async (payload: any) => {
  const { permissions, ownerId } = payload;
  const response = await axios.post(UPDATE_BLOCKED_PERMISSION, {
    permissions,
    ownerId,
  });
  return response.data;
};

export const getPermission = async (query: any) => {
  const response = await axios.get(GET_BLOCKED_PERMISSION, {
    params: {
      ownerId: query,
    },
  });
  return response.data;
};