import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './authReducer';
import { adminReducer } from './adminReducer';
import { universityTypeReducer } from './universityTypeReducer';

export const rootReducer = combineReducers({
	authReducer,
	adminReducer,
	universityTypeReducer
});
